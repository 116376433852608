body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

ul.e-upload-files {
  max-height: 300px !important;
  overflow: auto !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media print
{
  iframe {display:none;}
}