.e-rte-toolbar .e-rte-file-manager::before, .e-rte-dropdown-popup .e-rte-file-manager::before {
    content: '\e609';
}
.imgUrl {
    display: none;
}
button.e-control.e-btn.e-lib.e-flat.e-insertImage.e-primary {
    display: none;
}

.e-search-wrap {
    display: none;
}

button.e-control.e-btn.e-lib.e-flat.e-cancel:before {
    content: "OK";
    position: absolute;
    background: white;
    width: 55px;
}
button.e-control.e-btn.e-lib.e-flat.e-cancel:hover:before, button.e-control.e-btn.e-lib.e-flat.e-cancel:focus:before, 
button.e-control.e-btn.e-lib.e-flat.e-cancel:focus, button.e-control.e-btn.e-lib.e-flat.e-cancel:active,
button.e-control.e-btn.e-lib.e-flat.e-cancel:active:before{
    background-color: rgb(246 246 246) !important;
    box-shadow: none !important;
}

span.e-btn-icon.e-rte-file-manager.e-icons {
    position: relative;
    overflow: visible;
}
span.e-btn-icon.e-rte-file-manager.e-icons:after {
    content: "Attach File";
    position: absolute;
    top: 24px;
    left: 0;
    background: #fff;
    border: 1px solid;
    padding: 0 5px;
    opacity: 0;
    transition: 0.5s all;
}
span.e-btn-icon.e-rte-file-manager.e-icons:hover:after{
    opacity: 1;
}
.e-toolbar-items .e-toolbar-item:nth-child(23) >button {
    overflow: visible;
}

.form-control-file {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 80px;
    outline: none;
    visibility: hidden;
    cursor: pointer;
    background-color:none;
    box-shadow: 0 0 5px solid currentColor;
  }
  .form-control-file:before {
    content: attr(data-title);
    position: absolute;
  
    left: 0;
    width: 100%;
    line-height: 2em;
    padding: 20px 0;
    opacity: 1;
    visibility: visible;
    text-align: center;
    border: 0.25em dashed #140a9a;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
  }
  .inputfile{
      margin-bottom: 8px;
  }
  .uploadprogress {
    display: inline-block;
    width: 95%;
    margin-right: 10px;
}
.AutocompleteEmails .MuiInputBase-root.MuiFilledInput-root {
    background: #fff;
}